import { defineMessages } from "gatsby-plugin-react-intl"

export const websiteMessages = defineMessages({
  title: {
    defaultMessage: "learntray.com - Track learning progress",
    description: "website title",
  },
  description: {
    defaultMessage:
      "One place to track learning from many Internet websites. Browse Internet guides, tutorials or courses and add it to your plan.",
    description: "website description",
  },
})
