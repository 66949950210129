import React from "react"
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-react-intl"
import { websiteMessages } from "../../../messages/website"

interface Props {
  subtitle?: string
}

const SEO: React.FC<Props> = ({ subtitle }) => {
  const intl = useIntl()

  const title = intl.formatMessage(websiteMessages.title)
  const description = intl.formatMessage(websiteMessages.description)

  return (
    <Helmet defer={false}>
      <html lang={intl.locale} />
      <meta charSet="utf-8" />
      <title>{subtitle ? `${title} - ${subtitle}` : title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export { SEO }

{
  /* <script
id="help-ukraine-win"
async={true}
src="https://helpukrainewinwidget.org/cdn/widget.js"
data-type="two"
data-position="bottom-right"
></script> */
}
